<template>
  <v-card :loading="loading" :disabled="loading" v-if="!lodash.isEmpty(evento)">
    <v-card-title class="subtitle-1 lightgray">
      {{ evento.cliente }}
      <v-spacer></v-spacer>
      <v-btn icon @click="voltarEventos">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-6">
      <v-row>
        <!-- Nome Completo -->
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="selectedCliente"
            :items="clientes"
            :loading="loadingCliente"
            :search-input.sync="search"
            @click:clear="clearSearch"
            @keyup="buscaClientes"
            clearable
            dense
            filled
            hide-details
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id_cliente"
            :label="$tc('pages.cliente')"
            :placeholder="$tc('msg.digitarbuscar')"
            prepend-inner-icon="search"
            return-object
          ></v-autocomplete>
        </v-col>

        <!-- Classificações -->
        <v-col cols="12" md="6">
          <v-select
            v-model="evento.classificacao_id"
            :items="classificacoes"
            @change="loadTipos"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.classificacao', 2)"
          ></v-select>
        </v-col>

        <!-- Origen -->
        <v-col cols="12" md="6">
          <v-select
            v-model="evento.origem_id"
            :items="origens"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('pages.origem')"
          ></v-select>
        </v-col>

        <!-- Evento Tipo -->
        <v-col cols="12" md="6" v-if="evento.classificacao_id">
          <v-combobox
            v-model="selectedTipo"
            :items="filteredEventoTipos"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="$tc('audits.tipoevento', 2)"
          ></v-combobox>
        </v-col>

        <!-- Prioridade -->
        <v-col cols="12" md="6" v-if="selectedTipo">
          <v-select
            v-model="evento.prio"
            :items="prioridades"
            clearable
            item-text="text"
            item-value="value"
            dense
            filled
            hide-details
            :label="$tc('global.prioridade')"
          ></v-select>
        </v-col>

        <!-- Localização -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="evento.localizacao"
            :label="$tc('global.localizacao')"
            dense
            filled
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Descricao -->
        <v-col cols="12">
          <v-textarea
            v-model="evento.descricao"
            :placeholder="$tc('msg.escrevadescricao')"
            :label="$tc('global.descricao')"
            :rows="3"
            class="pa-0 ma-0"
            row-height="25"
            :counter="255"
            :rules="rules"
            filled
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <span class="caption ml-2">
        {{ evento.protocolo }}
      </span>

      <v-spacer></v-spacer>
      <v-btn
        @click="updateEvento"
        :disabled="!valid"
        depressed
        dark
        color="toolbar"
      >
        {{ $tc("global.atualizar") }}
        <v-icon right>save</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getEvento, putEvento } from "@/services/api/eventos.api.js";
import { getListClientes } from "@/services/api/clientes.api.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "EventoEditar",
  props: ["eventoId"],
  data: () => ({
    componentKey: 0,
    evento: {
      descricao: "",
      tipo_evento_id: null,
      prio: null,
      cliente_id: null,
      classificacao_id: null,
      origem_id: null,
    },
    time: null,
    menu2: false,
    filteredEventoTipos: [],
    loading: false,
    loadingNovaNota: false,
    clientes: [],
    selectedCliente: null,
    selectedTipo: null,
    loadingCliente: false,
    notaTextFocus: false,
    agendarPara: false,
    search: "",
    rules: [(v) => v.length <= 255 || "Maximo de 255 caracteres"],
  }),
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
      usuarios: (state) => state.usuarios,
    }),
    ...mapState("Eventos", {
      classificacoes: (state) => state.classificacoes,
      eventoTipos: (state) => state.eventoTipos,
      origens: (state) => state.origens,
      prioridades: (state) => state.prioridades,
    }),
    nome() {
      let splitName = this.$store.state.Usuario.usuario.name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      let name = `${firstName} ${lastName}`;
      return name;
    },
    valid() {
      if (
        this.evento.descricao &&
        this.evento.descricao.length <= 255 &&
        this.evento.tipo_evento_id != null &&
        this.evento.prio != null &&
        this.evento.cliente_id != null &&
        this.evento.classificacao_id != null &&
        this.evento.origem_id != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    evento_id() {
      return this.$route.params.eventoId;
    },
  },
  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.evento.cliente_id = this.selectedCliente.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.evento.tipo_evento_id = this.selectedTipo.id;
      }
    },
  },
  methods: {
    voltarEventos() {
      if (this.$route.matched[0].path) {
        this.$router.push(this.$route.matched[0].path);
      } else {
        this.$router.push(this.$route.matched[1].path);
      }
    },
    buscaUsuario(id) {
      let user = this.usuarios.find((item) => item.id === id);
      if (user) return user.name;
    },
    buscaClientes(event) {
      if (this.search) {
        if (this.search.length > 3 && event.key != "Backspace") {
          this.getClientes(`?search=${this.search}`);
        }
      }
    },
    clearSearch() {},
    loadTipos(id) {
      if (!this.eventoTipos) return;
      let filter = this.eventoTipos.filter(
        (item) => item.classificacao_id === id
      );
      this.filteredEventoTipos = filter;
    },
    getClientes(filtros) {
      this.loadingCliente = true;
      this.clientes = null;

      getListClientes(filtros)
        .then((response) => {
          this.clientes = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingCliente = false;
        });
    },
    ...mapActions("Eventos", {
      getClassificacoes: "getClassificacoes",
      getEventosTipos: "getEventosTipos",
      getOrigens: "getOrigens",
    }),
    getEvento() {
      this.loading = true;
      getEvento(this.evento_id)
        .then((response) => {
          this.evento = response;
          this.time = response.agendado_hora;
          this.loadTipos(this.evento.classificacao_id);
          let tipo = this.eventoTipos.find(
            (item) => item.id === this.evento.tipo_evento_id
          );
          if (tipo) {
            this.selectedTipo = tipo;
          }
          getListClientes(`?search=${this.evento.cliente_id}`).then(
            (response) => {
              this.clientes = response;
              this.selectedCliente = response[0];
            }
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateEvento() {
      this.loading = true;
      console.log(this.evento);
      putEvento(this.evento_id, this.evento)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.atualizadosucesso"));
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (!this.classificacoes) {
      this.getClassificacoes();
    }
    if (!this.eventoTipos) {
      this.getEventosTipos();
    }
    if (!this.origens) {
      this.getOrigens();
    }
    this.getEvento();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .headline {
  input {
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
  }
}
</style>
